import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import Typography from 'lib/components/Typography'
import {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {NewTierFooter} from '../Tier.Styled'
import LoadingPanel from 'lib/components/LoadingPanel'
import PartnerService from 'app/services/PartnerService'
import {FormTypes} from 'lib/components/Form/Form'
import {getColor, getTierRequestObj, initialTierReqObj, updateTierReqObj} from '../utils'
import Colors from 'styles/Colors'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import {Modal} from 'antd'
import NewTierSystemForm from './NewTierSystemForm'
// import PreviewTier from './PreviewTier'
import {
  CenteredAlignedLabel,
  ExtraPaddedButton,
  MarginBottomWrapper,
  ModalTitle,
  ReviewHeader,
  Seperator,
  StatusCircleStyled,
  StyledModalContent,
} from 'app/common/components/Styled/common.styled'
import IconSuccess from 'app/common/icons/IconSuccess'
import Appconfig from 'app/common/helpers/AppConfig'
import RewardService from 'app/services/RewardService'
import TierService from 'app/services/TierService'
import {isEmpty} from 'lodash'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {TIER_DELETE} from 'app/common/helpers/UserFunctions'
import IconDelete from 'app/common/icons/IconDelete'

export type AddNewTierParam = {
  tierId: string | 'new' | 'view'
}

export interface TierRefType {
  validate(): boolean
  getState(): TierTypes.TierMasterResponseBL
}
interface AddNewTierProps {
  isViewOnly?: boolean
}

const AddNewTier: React.FC<AddNewTierProps> = ({isViewOnly}): JSX.Element => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  const navigate = useNavigate()
  const {tierId} = useParams<AddNewTierParam>()
  const actualTierId = atob(tierId || '')
  const {roles} = useAuth()
  const showDelete = roles.includes(TIER_DELETE)
  const [activeStep, setActiveStep] = useState<number>(tierId === 'new' ? 0 : 1)
  const tierFormRef = useRef<TierRefType>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [loadingTier, setLoadingTier] = useState<boolean>(false)
  const [individualTierSystem, setIndividualTierSystem] = useState<TierTypes.TierMasterResponseBL>()
  const [partnerCodeOptions, setPartnerCodeOptions] = useState<FormTypes.Options[]>([])
  const [partnerCode, setPartnerCode] = useState('')
  const [processing, setProcessing] = useState<boolean>(false)
  const [created, setCreated] = useState<boolean>(false)
  const [updated, setUpdated] = useState<boolean>(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const [published, setPublished] = useState<boolean>(false)
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)
  const [rewardsDataOptions, setRewardsDataOptions] = useState<FormTypes.Options[]>([])
  const isLive = getTierRequestObj.tierStatus === 'APPROVED'
  const updateTierReqPartnerData = (data: PartnerTypes.PartnerBL[]) => {
    const partnerCodeFromRequest = getTierRequestObj.partnerCode
    const defaultCode = data.find((partnerOption) => partnerOption.isDefaultPartner)
    if (partnerCodeFromRequest) {
      const hasPartnerCode = data.find((partnerOption) => partnerOption.partnerCode === partnerCodeFromRequest)
      if (!hasPartnerCode) {
        getTierRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
      }
    } else {
      getTierRequestObj.partnerCode = defaultCode ? defaultCode.partnerCode : ''
    }
    setPartnerCode(getTierRequestObj.partnerCode)
  }

  const getPartnerCodes = () => {
    PartnerService.getPartners().then((res) => {
      const data = res.data.data
      const partnerOptions = data.map((item) => ({value: item.partnerCode, label: item.partnerName}))
      setPartnerCodeOptions(partnerOptions)
      updateTierReqPartnerData(data)
    })
  }
  const resetModalStates = () => {
    setCreated(false)
    setUpdated(false)
    setPublished(false)
    setConfirmDelete(false)
    setDeleteSuccess(false)
    setIsPublishing(false)
  }

  const getApprovedRewards = (params: RewardsTypes.RewardSearchParam): void => {
    const reqParam: RewardsTypes.RewardSearchParam = {
      ...params,
      isAll: true,
      IsTierBenefit: true,
      enabled: true,
    }
    RewardService.search(reqParam)
      .then((res) => {
        const data = res.data
        const updatedData = data.data.map((item) => ({
          label: item.voucherTypeName,
          value: item.voucherTypeCode,
        }))
        setRewardsDataOptions([...updatedData])
      })
      .finally(() => {})
  }
  const getTierData = (tierSystemkey: string) => {
    setLoadingTier(true)
    TierService.getTiers({TierSystemKey: tierSystemkey})
      .then((res) => {
        const {data} = res.data
        if (data.length) {
          setIndividualTierSystem(data[0])
          updateTierReqObj(data[0])
        }
      })
      .finally(() => {
        setLoadingTier(false)
      })
  }

  useEffect(() => {
    if (tierId !== 'new') {
      getTierData(actualTierId)
    }
  }, [tierId, actualTierId])

  useEffect(() => {
    getPartnerCodes()
    getApprovedRewards(initialPaginationObj)
    return () => {
      updateTierReqObj(initialTierReqObj)
    }
  }, [])

  const handleCompleted = () => {
    setOpen(false)
    setCreated(false)
    if (activeStep === 0) {
      setActiveStep(1)
      const tierSystemKey = btoa(individualTierSystem?.tierSystemKey || 'sample')
      navigate(`/program/tiers/view/${tierSystemKey}`)
    } else {
      setDeleteSuccess(false)
      setOpen(false)
      navigate(`/program/tiers`)
    }
  }

  const handleCancelNewTier = (): void => {
    if (activeStep === 1) {
      handleCancelConfirm()
    } else {
      setOpen(true)
    }
  }

  const createPreview = (status: string | null): void => {
    if (tierFormRef.current?.validate()) {
      let tierData = tierFormRef.current.getState()
      tierData = {...tierData, tierStatus: status || getTierRequestObj.tierStatus, tierList: [...tierData.tierList]}
      if (tierId !== 'new') {
        tierData.tierSystemKey = actualTierId
      }
      const tierSystemKey = isEmpty(actualTierId) ? null : tierData.tierSystemKey
      setOpen(true)
      setProcessing(true)
      //setIndividualTierSystem(tierData)
      // updateTierReqObj(tierData)
      console.log(tierData)
      TierService.saveTierSystem(tierData, {tierSystemKey: tierSystemKey, partnerCode: tierData.partnerCode})
        .then((res) => {
          const data = res.data.data
          setIndividualTierSystem(data)
          if (status === 'DRAFT' || status === 'INPROGRESS') {
            if (tierId !== 'new') {
              setUpdated(true)
            } else {
              setCreated(true)
            }
          } else {
            if (!isLive) {
              setUpdated(true)
            } else {
              setPublished(true)
            }
          }
          updateTierReqObj(data)
        })
        .catch(() => {
          setOpen(false)
          setProcessing(false)
        })
        .finally(() => {
          setProcessing(false)
        })
    }
  }

  const handleCancelConfirm = () => {
    updateTierReqObj(initialTierReqObj)
    navigate(`/program/tiers`)
  }

  const handleCloseModal = () => {
    handleCancelConfirm()
  }

  const handleCancelModal = () => {
    resetModalStates()
    setOpen(false)
  }

  const handleOk = () => {
    handleCancelConfirm()
  }

  const publishTier = () => {
    if (tierFormRef.current?.validate()) {
      setOpen(true)
      setIsPublishing(true)
    }
  }

  const handlePublishTier = () => {
    setProcessing(true)
    setIsPublishing(false)
    createPreview('APPROVED')
  }

  const handleDeleteTierSystem = () => {
    setConfirmDelete(true)
    setOpen(true)
  }

  const handleDeleteConfirm = () => {
    setProcessing(true)
    setConfirmDelete(false)
    TierService.deleteTier({tierSystemKey: individualTierSystem?.tierSystemKey, partnerCode: individualTierSystem?.partnerCode})
      .then((res) => {
        const data = res.data
        if (data) {
          setProcessing(false)
          setDeleteSuccess(true)
        }
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  const renderSaveButton = () => {
    return (
      <Button
        onClick={activeStep === 0 ? () => createPreview('INPROGRESS') : publishTier}
        type="primary"
        disabled={partnerCode === ''}
        // disabled={disableSave}
        $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
      >
        {activeStep === 1 ? 'Save and Publish' : 'Create & Preview'}
      </Button>
    )
  }

  const renderModalBody = (): JSX.Element => {
    if (processing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (updated) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Tier Updated
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                The tier named <b>{individualTierSystem?.tierSystemName}</b> has been updated.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleCompleted}>
                Continue
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    if (created) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              New Tier Created
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                The new tier named <b>{individualTierSystem?.tierSystemName}</b> has been created but it is not live yet.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleCompleted}>
                Continue
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }

    if (isPublishing) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <Typography.Title level={1}>Are you sure?</Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">Are you sure you want to publish {getTierRequestObj.tierSystemName}?</Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" key="submit" onClick={handlePublishTier}>
              Publish
            </Button>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="link" key="back" onClick={handleCancelModal}>
              Back
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    if (confirmDelete) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <Typography.Title level={1}>Are you sure?</Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">Are you sure you want to delete {getTierRequestObj.tierSystemName}?</Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <ExtraPaddedButton type="primary" key="submit" onClick={handleDeleteConfirm}>
              Delete
            </ExtraPaddedButton>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="link" key="back" onClick={handleCancelModal}>
              Back
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    if (deleteSuccess) {
      return (
        <>
          <ModalTitle>
            <IconDelete color={Colors.RED} />
            &nbsp;
            <Typography.Title level={2} uppercase>
              Draft Tier System Deleted
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%" textAlign="center">
              <Typography.Paragraph size="medium">
                <b>{individualTierSystem?.tierSystemName}</b> has been deleted.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleCompleted}>
                Continue
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    if (published) {
      return (
        <>
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Tier System is now live
            </Typography.Title>
          </ModalTitle>
          <StyledModalContent align="center">
            <MarginBottomWrapper>
              <Seperator />
            </MarginBottomWrapper>
            <MarginBottomWrapper width="80%">
              <Typography.Paragraph size="medium">
                <b>{individualTierSystem?.tierSystemName}</b> is published and live.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" onClick={handleCompleted}>
                Continue
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        </>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">Are you sure you want to cancel adding {getTierRequestObj.tierSystemName}?</Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="primary" key="submit" onClick={handleOk}>
            Continue with cancel
          </Button>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  const renderTiersBody = (): JSX.Element => {
    if (loadingTier) {
      return <LoadingPanel />
    }
    if (tierId !== 'new' && !individualTierSystem) {
      return <div>No Tier found with {actualTierId}</div>
    }

    return (
      <NewTierSystemForm
        ref={tierFormRef}
        partnerCodeOptions={partnerCodeOptions}
        rewardsDataOptions={rewardsDataOptions}
        isViewOnly={Boolean(isViewOnly)}
        activeStep={activeStep}
        isLive={isLive}
      />
    )
    // return <PreviewTier />
  }

  const renderTiersFooter = () => {
    return (
      <NewTierFooter>
        {activeStep === 0 ? (
          <Button type="link" onClick={handleCancelNewTier}>
            Cancel
          </Button>
        ) : (
          <Button type="default" onClick={() => (isLive ? createPreview('APPROVED') : createPreview('DRAFT'))}>
            {isLive ? 'Save and Publish' : 'Save Draft'}
          </Button>
        )}
        {!isLive && renderSaveButton()}
      </NewTierFooter>
    )
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs backLinkLabel="Tier Master" backLinkPath={`/program/tiers`} activePageLabel={isViewOnly ? 'Tier Detail' : 'Add New Tier'} />
        <Button type="link" onClick={handleCancelNewTier}>
          {activeStep === 1 ? 'Back' : 'Cancel'}
        </Button>
      </BreadcrumbsHeaderWrapper>
      {activeStep === 0 && (
        <StyledHeader>
          <Typography.Title level={2}>Add New Tier System</Typography.Title>
        </StyledHeader>
      )}
      <div>
        {activeStep === 1 && individualTierSystem && (
          <ReviewHeader>
            <Typography.Title level={2}>{getTierRequestObj.tierSystemName}</Typography.Title>
            {individualTierSystem ? (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <CenteredAlignedLabel>
                  <StatusCircleStyled color={getColor(getTierRequestObj.tierStatus)}></StatusCircleStyled>{' '}
                  <Typography.Text>{getTierRequestObj.tierStatus}</Typography.Text>
                </CenteredAlignedLabel>
                {showDelete && !isLive && (
                  <Button type="link" onClick={handleDeleteTierSystem}>
                    Delete
                  </Button>
                )}
              </div>
            ) : (
              <></>
            )}
          </ReviewHeader>
        )}
        {renderTiersBody()}
      </div>
      {renderTiersFooter()}
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCloseModal} footer={null}>
          {renderModalBody()}
        </Modal>
      )}
    </div>
  )
}

export default AddNewTier
