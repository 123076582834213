import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import Appconfig from 'app/common/helpers/AppConfig'
import {useEffect, useState} from 'react'
import convertDateToString from 'utils/convertDateToString'
import RewardService from 'app/services/RewardService'
const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Award Type',
    id: 'awardType',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Award Name',
    id: 'awardName',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Award Tokencode',
    id: 'awardTokencode',
    textAlign: 'center',
    width: '20%',
  },

  {
    title: 'Date',
    id: 'date',
    textAlign: 'left',
    width: '12%',
  },
  {
    title: 'Award Points',
    id: 'awardPoints',
    textAlign: 'center',
    width: '12%',
  },
  {
    title: 'Status',
    id: 'status',
    textAlign: 'left',
    width: '12%',
  },
  {
    title: 'Validity',
    id: 'validity',
    textAlign: 'right',
    width: '12%',
  },
]
// const rowData: DataTableTypes.Row[] = [
//   {
//     awardType: 'dfd',
//     awardName: 'Gift Voucher',
//     date: '12/12/2022',
//     activityType: 'Shop',
//     status: 'Redeem',
//     validity: '12/12/2002',
//     awardPoints: '125',
//   },
//   {
//     awardType: 'dfd',
//     awardName: 'Gift Voucher',
//     date: '12/12/2022',
//     activityType: 'Shop',
//     status: 'Redeem',
//     validity: '12/12/2002',

//     awardPoints: '125',
//   },
//   {
//     awardType: 'dfd',
//     awardName: 'Gift Voucher',
//     date: '12/12/2022',
//     activityType: 'Shop',
//     status: 'Redeem',
//     validity: '12/12/2002',

//     awardPoints: '125',
//   },
//   {
//     awardType: 'dfd',
//     awardName: 'Gift Voucher',
//     date: '12/12/2022',
//     activityType: 'Shop',
//     status: 'Redeem',
//     validity: '12/12/2002',

//     awardPoints: '125',
//   },
// ]

interface AwardsProps {
  pageSize?: number

  pageSizeOptions?: number[]
  membershipCardNumber?: string
}

const Awards: React.FC<AwardsProps> = ({
  pageSize = Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
  pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS,
  membershipCardNumber,
}) => {
  const initialVoucherResponse: ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const [isFetching, setIsFetching] = useState(true)
  const [voucherResponse, setVoucherResponse] = useState<ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]>>(initialVoucherResponse)
  const paginationObj: VoucherTypes.VocherDetail = {
    PageCount: pageSize,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
    MembershipCode: membershipCardNumber,
  }

  const getVouchers = (params: VoucherTypes.VocherDetail) => {
    RewardService.searchRewards({...params, Strict: true})
      .then((res) => {
        const data = res.data
        console.log('data', data)
        setVoucherResponse(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }
  // const convertDateToString = (date: Date): string => new Date(date).toLocaleDateString('en-GB')

  const getDateComponent = (item: RewardsTypes.RewardResponseBL): string => {
    switch (item.voucherStatus) {
      case 'REDEEMED':
        return convertDateToString(item.convertedDate)

      case 'ISSUED':
        return convertDateToString(item.issuedDate)

      case 'ASSIGNED':
        return convertDateToString(item.assignedDate)

      case 'CANCELLED':
        return convertDateToString(item.cancelledDate)

      // case 'AVAILABLE':
      //   return convertDateToString(item.assignedDate)
      // break
      default:
        return ''
    }
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (voucherResponse && voucherResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = voucherResponse
      data.map((item) => {
        const obj: DataTableTypes.Row = {
          awardType: item.voucherTypeCategory,
          awardName: item.voucherName,
          awardPoints: item.points,
          date: getDateComponent(item),
          status: item.voucherStatus,
          validity: item.validUntil,
          awardTokencode: item.voucherTokenCode,
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }
  const handleOnChange = (page: number, pageSize: number) => {
    getVouchers({...paginationObj, PageCount: pageSize, PageNumber: page})
  }
  const renderNoRecordFound = () => {
    return <NoRecordFound>No awards available!</NoRecordFound>
  }

  useEffect(() => {
    getVouchers(paginationObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {pageIndex, count} = voucherResponse.pageInfo
  return (
    <div>
      <DataTable>
        <DataTable.Header columns={dataTableColumns} />
        <DataTable.Rows loading={isFetching} columns={dataTableColumns} rows={generateRowData()} noRecordFoundComponent={renderNoRecordFound()} />
        <DataTable.Pagination
          onChange={handleOnChange}
          current={pageIndex}
          pageSizeOptions={pageSizeOptions}
          total={count}
          defaultPageSize={pageSize}
          showSizeChanger
        />
      </DataTable>
    </div>
  )
}
Awards.defaultProps = {
  pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
  pageSizeOptions: Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS,
}
export default Awards
