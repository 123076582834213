import {Col, Row} from 'antd'
import Form from 'lib/components/Form'
import {
  InputNumberUnit,
  InputNumberWrapper,
  NewRewardFormWrapper,
  RewardDetailsWrapper,
  RewardRestrictionWrapper,
  RewardTriggerSectionWrapper,
  TriggerDetailsWrapper,
} from '../Reward.Styled'

import {FormTypes} from 'lib/components/Form/Form'
import {forwardRef, useState, useImperativeHandle} from 'react'
import {
  LIMIT_TYPES,
  VoucherTypeGroupOptions,
  defaultInventoryNotificationQty,
  getChildRewardPayloadArr,
  getRewardsRequestObj,
  getTagNameByCode,
  inventoryOptions,
  isAscending,
  limitOptions,
  noDateCheckboxOptions,
  restrictionsData,
  rewardStatus,
  rewardTypeOptions,
  triggerRules,
  updateRewardRuleData,
  updateRewardsRequestObj,
} from './utils'
import dayjs from 'dayjs'
import {handleDisableFromDate, handleDisableToDate} from 'utils/DatepickerUtils'
import {toast} from 'react-hot-toast'
import {cloneDeep} from 'lodash'
import Typography from 'lib/components/Typography'
import RewardTriggerSection from './RewardTriggerSection'
import {useParams} from 'react-router-dom'
import {AddNewRewardParam} from '.'
import RewardRestrictions from './RewardRestrictions'
import {CheckboxContainer} from 'app/pages/Promotions/CreatePromotion/Steps/Steps.styled'
import ToggleButton from 'lib/components/ToggleButton'
import {TriggerLimitsWrapper} from 'app/pages/Promotions/CreatePromotion/Steps/Trigger/Trigger.styled'
import RewardLimit from './RewardLimit'
import {CheckboxValueType} from 'antd/es/checkbox/Group'
import Tabs from 'app/common/components/Tabs'
import RewardImageGallary from './RewardImageGallary'
import RichTextEditor from 'app/common/components/RichTextEditor'
import TextArea from 'lib/components/TextArea'
import Appconfig from 'app/common/helpers/AppConfig'
import {utcDate, utcWithTimeZone} from 'utils/convertDateToString'

export interface RewardBasicInfoTypes {
  voucherTypeCode: string
  partnerCode: string
  voucherTypeName: string
  voucherTypeSubCategory: string
  voucherLimit: number
  point: number
  amount: number
  validFrom: RewardsTypes.DateTimeStringType
  validUntil: RewardsTypes.DateTimeStringType
  voucherTypeDescription: string
  enabled: boolean
  voucherStatus?: string
  voucherTypeCategory?: string
  voucherTypeId?: number
  url?: string
  value: number
  isRedemption: boolean
  isTierBenefit: boolean
  isLimitedInventory?: boolean
  voucherTypeSkuCode?: string
  voucherTypeGroup?: number
  childRewards?: string[]
  isHighlightedReward?: boolean
  voucherTypeShortDescription?: string
  voucherTypeFullDescription?: string
  voucherTypeDisclaimer?: string
  voucherTagCode?: RewardsTypes.RewardTag
  categoryList?: string[]
  rewardDynamicBody: string
  rewardDynamicFooter: string
  rewardShippingTimeFrame: string
  isInventoryNotification?: boolean
  inventoryNotificationQtyList: RewardNotificationType[]
}

export type RewardNotificationType = {
  quantity: number
  isNotificationSent: boolean
}
export type ImageGallaryStateType = {
  rewardThumbnail: string
  rewardPrimaryImage: string
  rewardAdditionalImage: string[]
}

interface RewardHeaderErrorType {
  voucherTypeName: boolean
  voucherTypeCode: boolean
  startDateEror: boolean
  endDateError: boolean
  stateError: boolean
}

export type RewardBasicValueType = string | number | boolean | null

export interface RewardRefType {
  validate(): boolean
}

interface NewRewardFormProps {
  partnerCodeOptions: FormTypes.Options[]
  tiersData: MemebershipTypes.TierCountType[]
  handleTierTypeChange(id: string, value: boolean): void
  onTierSelection(id: string, selectedTiers: RewardsTypes.RewardTierInfo[]): void
  onRestrictionToggle(id: string, value: boolean): void
  onStateChange(id: string, values: string[]): void
  updateRewardTriggerToggle(id: string, value: boolean): void
  getMemberCount: (partnerCode: string) => void
  rewardDataOptions: FormTypes.Options[]
  tagsDataOptions: FormTypes.Options[]
  categoryDataOptions: FormTypes.Options[]
}

const NewRewardForm = forwardRef<RewardRefType, NewRewardFormProps>(
  (
    {
      partnerCodeOptions,
      tiersData,
      handleTierTypeChange,
      onTierSelection,
      onRestrictionToggle,
      onStateChange,
      updateRewardTriggerToggle,
      getMemberCount,
      rewardDataOptions,
      tagsDataOptions,
      categoryDataOptions,
    },
    ref
  ) => {
    let initialRewardReqObj = getRewardsRequestObj
    const {rewardRuleData} = initialRewardReqObj
    const {rewardId} = useParams<AddNewRewardParam>()
    const actualRewardId = atob(rewardId || '')
    const isNewReward = actualRewardId === 'new'
    const initialState: RewardBasicInfoTypes = {
      voucherTypeCode: initialRewardReqObj.voucherTypeCode,
      partnerCode: initialRewardReqObj.partnerCode,
      voucherTypeName: initialRewardReqObj.voucherTypeName ?? '',
      voucherTypeSubCategory: initialRewardReqObj.voucherTypeSubCategory,
      voucherLimit: initialRewardReqObj.voucherLimit || 0,
      point: initialRewardReqObj.point,
      amount: initialRewardReqObj.amount,
      validFrom: initialRewardReqObj.validFrom
        ? utcDate(utcWithTimeZone(initialRewardReqObj.validFrom), Appconfig.DATE_TIME_FORMAT)
        : utcDate(utcWithTimeZone(new Date().toISOString()), Appconfig.DATE_TIME_FORMAT),
      validUntil: initialRewardReqObj.validUntil ? utcDate(utcWithTimeZone(initialRewardReqObj.validUntil), Appconfig.DATE_TIME_FORMAT) : null,
      voucherTypeDescription: initialRewardReqObj.voucherTypeDescription,
      enabled: initialRewardReqObj.enabled,
      url: initialRewardReqObj.url,
      value: initialRewardReqObj.value,
      isRedemption: initialRewardReqObj.isRedemption,
      isTierBenefit: initialRewardReqObj.isTierBenefit,
      isLimitedInventory: initialRewardReqObj.isLimitedInventory || false,
      voucherTypeCategory: initialRewardReqObj.voucherTypeCategory || 'BENEFIT',
      voucherTypeSkuCode: initialRewardReqObj.voucherTypeSkuCode || '',
      voucherTypeGroup: initialRewardReqObj.voucherTypeGroup || 3,
      childRewards:
        initialRewardReqObj.childRewards && initialRewardReqObj.childRewards.length > 0
          ? initialRewardReqObj.childRewards?.map((item) => item.rewardCode)
          : [],
      isHighlightedReward: initialRewardReqObj.isHighlightedReward || false,
      voucherTypeDisclaimer: initialRewardReqObj.voucherTypeDisclaimer || '',
      voucherTypeShortDescription: initialRewardReqObj.voucherTypeShortDescription || '',
      voucherTypeFullDescription: initialRewardReqObj.voucherTypeFullDescription || '',
      voucherTagCode: initialRewardReqObj.voucherTagCode || undefined,
      categoryList:
        initialRewardReqObj.categoryList && initialRewardReqObj.categoryList.length > 0
          ? initialRewardReqObj.categoryList?.map((item) => item.categoryCode)
          : [],
      rewardDynamicBody: '',
      rewardShippingTimeFrame: '',
      rewardDynamicFooter: '',
      isInventoryNotification: initialRewardReqObj.isInventoryNotification,
      inventoryNotificationQtyList: initialRewardReqObj.inventoryNotificationQtyList,
    }
    const errorState: RewardHeaderErrorType = {
      voucherTypeName: false,
      voucherTypeCode: false,
      startDateEror: false,
      endDateError: false,
      stateError: false,
    }

    let isEndDateDisabled = false
    let isCheckboxChecked = false
    if (isNewReward) {
      isEndDateDisabled = false
      isCheckboxChecked = false
    } else {
      isEndDateDisabled = initialState.validUntil ? false : true
      isCheckboxChecked = initialState.validUntil ? false : true
    }
    const [error, setErrorState] = useState<RewardHeaderErrorType>(errorState)
    const [state, setInititalState] = useState<RewardBasicInfoTypes>(initialState)
    const [isChecked, setIsChecked] = useState(isCheckboxChecked ? 'isChecked' : '')
    const [disabledEndDate, setEndDateDisable] = useState(isEndDateDisabled)
    const [isResetLimit, setIsResetLimit] = useState<boolean>(false)
    const [limitInfo, setLimitInfo] = useState<RewardsTypes.LimitData | null>(rewardRuleData ? rewardRuleData.voucherLimitRule : null)
    const [activeTabId, setActiveTabId] = useState(0)

    const handleValidate = () => {
      const rewardReqObj = getRewardsRequestObj
      let isValid = true
      if (!state.voucherTypeName) {
        isValid = false
        setErrorState({...errorState, voucherTypeName: true})
        toast.error('Reward Name is required')
      } else if (!state.validFrom) {
        isValid = false
        setErrorState({...errorState, startDateEror: true})
        toast.error('Start Date is required')
      } else if (!state.validUntil && !disabledEndDate) {
        isValid = false
        setErrorState({...errorState, endDateError: true})
        toast.error('End Date is required')
      } else if (state.isLimitedInventory && state.voucherLimit < 1) {
        isValid = false
        toast.error('Please enter inventory quantity.')
      } else if (
        state.isRedemption &&
        !rewardReqObj.rewardRuleData?.pointRedemption.triggerData.isAllTiers &&
        (!rewardReqObj.rewardRuleData?.pointRedemption.triggerData.tierData ||
          !rewardReqObj.rewardRuleData?.pointRedemption.triggerData.tierData?.length)
      ) {
        isValid = false
        toast.error('Please select atleast one tier for point redemption')
      } else if (
        state.isTierBenefit &&
        !rewardReqObj.rewardRuleData?.tierBenefit.triggerData.isAllTiers &&
        (!rewardReqObj.rewardRuleData?.tierBenefit.triggerData.tierData || !rewardReqObj.rewardRuleData?.tierBenefit.triggerData.tierData.length)
      ) {
        isValid = false
        toast.error('Please select atleast one tier for tier benefit')
      } else if (
        rewardReqObj.rewardRuleData?.stateRestriction.isRestrictionAvaliable &&
        (!rewardReqObj.rewardRuleData?.stateRestriction.state || !rewardReqObj.rewardRuleData?.stateRestriction.state?.length)
      ) {
        setErrorState({...errorState, stateError: true})
        isValid = false
        toast.error('Please select atleast one state for restrictions')
      } else if (rewardRuleData && rewardRuleData.voucherLimitRule?.campaignLimit && !rewardRuleData.voucherLimitRule?.campaignLimit.limitQuantity) {
        toast.error('Please select Limit quantity')
        isValid = false
      } else if (rewardRuleData && rewardRuleData.voucherLimitRule?.userLimit && !rewardRuleData.voucherLimitRule?.userLimit.limitQuantity) {
        isValid = false
        toast.error('Please select Limit quantity')
      } else if (state.categoryList != null && state.categoryList?.length > 10) {
        isValid = false
        toast.error('Categories can not be more than 10')
      } else if (state.childRewards != null && state.childRewards?.length > 10) {
        isValid = false
        toast.error('Child Rewards can not be more than 10')
      } else if (state.isInventoryNotification) {
        const isZero = state.inventoryNotificationQtyList.map((x) => x.quantity <= 0).includes(true)

        if (isZero) {
          isValid = false
          toast.error('Inventory notification Quantity should be greater than 0')
        }

        const isGreaterThanVoucherLimit = state.inventoryNotificationQtyList.map((x) => x.quantity >= state.voucherLimit).includes(true)
        if (isGreaterThanVoucherLimit) {
          isValid = false
          toast.error('Inventory notification Quantity should be Less than Inventory Quantity')
        }
        const data = state.inventoryNotificationQtyList.map((x) => x.quantity)

        if (!isAscending(data)) {
          isValid = false
          toast.error('Inventory notification Quantity should be in Ascending Order')
        }
      }
      return isValid
    }

    useImperativeHandle(ref, () => ({validate: handleValidate}))

    const handleDateChange = (date: string | null, key: keyof RewardsTypes.RewardsWhenData) => {
      const prevState = cloneDeep(state)
      let dateTimeString = date ? dayjs(date).format() : null
      // if (key === 'validUntil') {
      //   dateTimeString = date ? dayjs(date).endOf('day').format() : null
      //   getRewardsRequestObj[key] = dayjs(dateTimeString).format(Appconfig.DATE_TIME_SERVER)
      // } else {
      //   getRewardsRequestObj[key] = date ? dayjs(dateTimeString).endOf('day').format(Appconfig.DATE_TIME_SERVER) : null
      // }
      dateTimeString = dateTimeString ? dayjs(dateTimeString).format(Appconfig.DATE_TIME_SERVER) : null
      prevState[key] = dateTimeString
      getRewardsRequestObj[key] = dateTimeString
      setInititalState(prevState)
    }
    const inventoryCheckboxOptions: FormTypes.Options[] = [
      {
        label: 'Send notification updates for Reward’s inventory quantities',
        value: 'isChecked',
      },
    ]

    const onInventoryNotificationclick = (value: string[], key: string) => {
      let prevState = cloneDeep(state)
      let rewardReqObj = getRewardsRequestObj
      const isInventoryNotification = value.includes('isChecked')
      setInititalState({
        ...prevState,
        isInventoryNotification: isInventoryNotification,
        inventoryNotificationQtyList: isInventoryNotification ? cloneDeep(defaultInventoryNotificationQty) : [],
      })

      rewardReqObj = {
        ...rewardReqObj,
        isInventoryNotification: isInventoryNotification,
        inventoryNotificationQtyList: isInventoryNotification ? cloneDeep(defaultInventoryNotificationQty) : [],
      }

      updateRewardsRequestObj(rewardReqObj)
    }
    const onNoDataClick = (value: string[], key: string) => {
      const checkedValue = value[0]
      setEndDateDisable(!disabledEndDate)
      getRewardsRequestObj.validUntil = null
      setInititalState({...state, validUntil: null})
      setIsChecked(checkedValue)
    }

    const handleLimitedInventory = (value: boolean, key: string) => {
      let prevState = cloneDeep(state)
      let rewardReqObj = getRewardsRequestObj
      setInititalState({
        ...prevState,
        isLimitedInventory: value,
        voucherLimit: 0,
        voucherTypeCategory: value ? 'REWARD' : 'BENEFIT',
      })
      rewardReqObj = {
        ...rewardReqObj,
        isLimitedInventory: value,
        voucherLimit: 0,
        voucherTypeCategory: value ? 'REWARD' : 'BENEFIT',
      }
      updateRewardsRequestObj(rewardReqObj)
    }

    const handleChange = (value: FormTypes.ValueType, key: keyof RewardsTypes.RewardResponseBL) => {
      const prevState = cloneDeep(state)
      let rewardReqObj = getRewardsRequestObj

      if (key === 'amount') {
        setInititalState({
          ...prevState,
          [key]: value as number,
        })
        rewardReqObj = {
          ...rewardReqObj,
          [key]: value as number,
        }
      } else {
        setInititalState({
          ...prevState,
          [key]: value,
        })
        rewardReqObj = {
          ...rewardReqObj,
          validFrom: state.validFrom,
          [key]: value,
        }
      }
      if (key === 'partnerCode') {
        getMemberCount(value as string)
      }
      if (key === 'voucherTypeSubCategory') {
        if (rewardRuleData) {
          // rewardReqObj.rewardRuleData.voucherLimitRule = null
        }
        //setIsResetLimit(true)
      }
      if (key === 'childRewards') {
        const childRewardArr = getChildRewardPayloadArr(rewardDataOptions, value as string[])
        rewardReqObj = {
          ...rewardReqObj,
          [key]: childRewardArr,
        }
      }
      if (key === 'voucherTagCode') {
        var tagCode = getTagNameByCode(tagsDataOptions, value as string)
        setInititalState({
          ...prevState,
          [key]: {
            voucherTagCode: value as string,
            voucherTagName: tagCode,
          },
        })
        rewardReqObj = {
          ...rewardReqObj,
          [key]: {
            voucherTagCode: value as string,
            voucherTagName: tagCode,
          },
        }
      }
      if (key === 'categoryList') {
        const categoryCodes = value as string[]
        const categoryList: RewardsTypes.CategoryList[] = categoryCodes.map((code) => {
          const categoryName = getTagNameByCode(categoryDataOptions, code)
          return {
            categoryCode: code,
            categoryName: categoryName,
          }
        })

        rewardReqObj = {
          ...rewardReqObj,
          [key]: categoryList,
        }
      }

      if (key === 'voucherTypeGroup') {
        if (value === 1) {
          setInititalState({
            ...prevState,
            isLimitedInventory: false,
            isInventoryNotification: false,
            inventoryNotificationQtyList: [],
            voucherLimit: 0,
            voucherTypeCategory: 'BENEFIT',
            [key]: value as number,
          })
          rewardReqObj = {
            ...rewardReqObj,
            isLimitedInventory: false,
            isInventoryNotification: false,
            inventoryNotificationQtyList: [],
            voucherLimit: 0,
            voucherTypeCategory: 'BENEFIT',
            [key]: value as number,
          }
        } else {
          setInititalState({
            ...prevState,

            [key]: value as number,
          })
          rewardReqObj = {
            ...rewardReqObj,

            [key]: value as number,
          }
        }
      }
      updateRewardsRequestObj(rewardReqObj)
    }
    const handleQtyChange = (index: number, value: number) => {
      const prevState = state
      let rewardReqObj = getRewardsRequestObj
      prevState.inventoryNotificationQtyList = prevState.inventoryNotificationQtyList.map((item, i) => {
        if (i === index) {
          item.quantity = value
        }
        return item
      })
      rewardReqObj = {
        ...rewardReqObj,
        inventoryNotificationQtyList: prevState.inventoryNotificationQtyList,
      }

      setInititalState({
        ...prevState,
      })
      updateRewardsRequestObj(rewardReqObj)
    }

    const handleStateChange = (id: string, values: string[]) => {
      setErrorState((prevState) => ({...prevState, stateError: false}))
      onStateChange(id, values)
    }

    const handleTriggerChange = (id: string, value: boolean) => {
      if (id === 'pointRedemption') {
        setInititalState((prevState) => ({...prevState, isRedemption: value}))
        updateRewardTriggerToggle(id, value)
      } else {
        setInititalState((prevState) => ({...prevState, isTierBenefit: value}))
        updateRewardTriggerToggle(id, value)
      }
    }

    const setTriggerLimits = (values: CheckboxValueType[], type: string) => {
      let updatedLimits = limitInfo
      if (updatedLimits) {
        if (!values.length) {
          updatedLimits = {
            ...updatedLimits,
            [type]: null,
          }
        } else {
          updatedLimits = {
            ...updatedLimits,
            [type]: {
              limitQuantity: 0,
              limitFrequency: '',
            },
          }
        }
        setLimitInfo(updatedLimits)
        if (rewardRuleData) {
          updateRewardRuleData({
            ...rewardRuleData,
            voucherLimitRule: updatedLimits,
          })
        }
      } else {
        const limitsData = {
          userLimit:
            type === 'userLimit'
              ? {
                  limitQuantity: 0,
                  limitFrequency: '',
                }
              : null,
          campaignLimit:
            type === 'campaignLimit'
              ? {
                  limitQuantity: 0,
                  limitFrequency: '',
                }
              : null,
        }
        setLimitInfo(limitsData)
        if (rewardRuleData) {
          updateRewardRuleData({...rewardRuleData, voucherLimitRule: limitsData})
          // setTriggerRuleData({...latestTriggerRuleData, limitData: limitsData})
        } else {
          updateRewardRuleData({
            pointRedemption: {
              triggerData: {
                isAllTiers: true,
                tierData: null,
              },
            },
            tierBenefit: {
              triggerData: {
                isAllTiers: true,
                tierData: null,
              },
            },
            stateRestriction: {
              isRestrictionAvaliable: false,
              state: null,
            },
            voucherLimitRule: limitsData,
          })
        }
      }
    }

    const setRewardLimitsData = (value: FormTypes.ValueType | null, type: string, inputType: string) => {
      let updatedLimits = limitInfo
      if (updatedLimits) {
        const updatedLimitData = {...updatedLimits, [type]: {...updatedLimits[type as keyof typeof LIMIT_TYPES], [inputType]: value}}
        updatedLimits = updatedLimitData
      }
      if (getRewardsRequestObj.rewardRuleData?.voucherLimitRule) {
        getRewardsRequestObj.rewardRuleData.voucherLimitRule = updatedLimits
      }
      setLimitInfo(updatedLimits)
      setIsResetLimit(false)
    }

    const handleOnTabChange = (tabId: number): void => {
      setActiveTabId(tabId)
    }

    // const handleParentReward = (value: boolean, key: string) => {
    //   let prevState = cloneDeep(state)
    //   let rewardReqObj = getRewardsRequestObj
    //   setInititalState({
    //     ...prevState,
    //     [key]: value,
    //     childRewards: [],
    //   })
    //   rewardReqObj = {
    //     ...rewardReqObj,
    //     [key]: value,
    //     childRewards: [],
    //   }
    //   updateRewardsRequestObj(rewardReqObj)
    // }

    const handleHighlightAward = (value: boolean, key: string) => {
      let prevState = cloneDeep(state)
      let rewardReqObj = getRewardsRequestObj
      setInititalState({
        ...prevState,
        [key]: value,
      })
      rewardReqObj = {
        ...rewardReqObj,
        [key]: value,
      }
      updateRewardsRequestObj(rewardReqObj)
    }

    const renderTriggersContent = () => {
      return (
        <TriggerDetailsWrapper style={{borderBottom: 0}}>
          {Object.keys(triggerRules).map((key) => {
            const triggerData = rewardRuleData && rewardRuleData[key as keyof RewardsTypes.TriggerRulesData]
            return (
              <RewardTriggerSection
                key={key}
                id={key}
                triggerInfo={triggerRules[key as keyof RewardsTypes.TriggerRulesData]}
                disabled={isOtherThenToggleAndEndDateDisabled}
                isRedemption={state.isRedemption}
                isTierBenefit={state.isTierBenefit}
                rewardTriggerData={triggerData}
                tiersData={tiersData}
                onTriggerChange={handleTriggerChange}
                onSelectTierType={handleTierTypeChange}
                onTierSelection={onTierSelection}
              />
            )
          })}
        </TriggerDetailsWrapper>
      )
    }

    const renderRestrictionsContent = () => {
      return (
        <RewardRestrictionWrapper>
          {renderRewardLimits()}
          <RewardRestrictions
            rewardRestrictionInfo={restrictionsData}
            disabled={isOtherThenToggleAndEndDateDisabled}
            onRestrictionToggle={onRestrictionToggle}
            data={rewardRuleData && (rewardRuleData['stateRestriction' as keyof RewardsTypes.RewardRuleDataType] as RewardsTypes.RestrictionData)}
            onStateChange={handleStateChange}
            error={error.stateError}
          />
        </RewardRestrictionWrapper>
      )
    }

    const isRewardAndPartnerDisabled = actualRewardId !== 'new'
    const isOtherThenToggleAndEndDateDisabled =
      getRewardsRequestObj.voucherStatus?.toUpperCase() === rewardStatus.APPROVED ||
      getRewardsRequestObj.voucherStatus?.toUpperCase() === rewardStatus.LIVE

    const renderRewardLimits = () => {
      return (
        <RewardTriggerSectionWrapper>
          <Typography.Title level={3}>Set Limit(s)</Typography.Title>
          <TriggerLimitsWrapper>
            {limitOptions.map((limit) => {
              return (
                <RewardLimit
                  options={[limit]}
                  setTriggerLimits={setTriggerLimits}
                  setTriggerLimitsData={setRewardLimitsData}
                  data={limit.value === 'userLimit' ? limitInfo?.userLimit : limitInfo?.campaignLimit}
                  isReset={isResetLimit}
                  isOtherThenToggleAndEndDateDisabled={isOtherThenToggleAndEndDateDisabled}
                />
              )
            })}
          </TriggerLimitsWrapper>
        </RewardTriggerSectionWrapper>
      )
    }

    return (
      <NewRewardFormWrapper>
        <RewardDetailsWrapper>
          <Typography.Title level={3} color="primary">
            BASIC DETAILS
          </Typography.Title>
          <Row gutter={32}>
            <Col span={6}>
              <Form.Input
                id="voucherTypeCode"
                name="voucherTypeCode"
                label="Reward Id"
                placeholder="Enter a Reward Id"
                value={state.voucherTypeCode}
                onChange={handleChange}
                status={error.voucherTypeCode ? 'error' : undefined}
                disabled={true || isRewardAndPartnerDisabled || isOtherThenToggleAndEndDateDisabled}
                maxLength={50}
              />
            </Col>
            <Col span={6}>
              <Form.Input
                id="voucherTypeSkuCode"
                name="voucherTypeSkuCode"
                label="Alt ID/SKU"
                placeholder="Alternate ID"
                value={state.voucherTypeSkuCode}
                onChange={handleChange}
                status={error.voucherTypeCode ? 'error' : undefined}
                disabled={isOtherThenToggleAndEndDateDisabled}
                maxLength={50}
              />
            </Col>
            <Col span={6}>
              <Form.Select
                id="voucherTypeSubCategory"
                options={rewardTypeOptions}
                label="Reward Type"
                value={state.voucherTypeSubCategory}
                onChange={handleChange}
                disabled={isOtherThenToggleAndEndDateDisabled}
              />
            </Col>
            <Col span={6}>
              <Form.Select
                id="partnerCode"
                options={partnerCodeOptions}
                label="Partner Name"
                value={state.partnerCode || getRewardsRequestObj.partnerCode}
                onChange={handleChange}
                disabled={isRewardAndPartnerDisabled || isOtherThenToggleAndEndDateDisabled}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={18}>
              <Form.Input
                id="voucherTypeName"
                name="voucherTypeName"
                label="Reward Name"
                placeholder="Enter a Reward Name"
                autoComplete="off"
                value={state.voucherTypeName}
                onChange={handleChange}
                status={error.voucherTypeName ? 'error' : undefined}
                disabled={isOtherThenToggleAndEndDateDisabled}
              />
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={6}>
              <Form.DatePicker
                id="validFrom"
                disabledDate={(current: dayjs.Dayjs) => handleDisableFromDate(current, state.validFrom, state.validUntil)}
                value={state.validFrom}
                showTime
                onChange={handleDateChange}
                label="Start Date"
                status={error.startDateEror ? 'error' : undefined}
                disabled={isOtherThenToggleAndEndDateDisabled}
              />
            </Col>
            <Col span={6}>
              <Form.DatePicker
                id="validUntil"
                disabledDate={(current: dayjs.Dayjs) => handleDisableToDate(current, state.validFrom, state.validUntil)}
                value={state.validUntil}
                showTime
                onChange={handleDateChange}
                label="End Date"
                status={error.endDateError ? 'error' : undefined}
                disabled={disabledEndDate}
              />
            </Col>
            <Col style={{marginTop: '16px'}}>
              <CheckboxContainer>
                <Form.Checkbox id="noenddate" options={noDateCheckboxOptions} value={[isChecked]} onChange={onNoDataClick} />
              </CheckboxContainer>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <Form.Radio
                label="Is this a Parent Reward?"
                id="voucherTypeGroup"
                value={state.voucherTypeGroup}
                options={VoucherTypeGroupOptions}
                onChange={handleChange}
                disabled={isOtherThenToggleAndEndDateDisabled}
              />
              {/* <ToggleButton
                label="Is this a Parent Reward?"
                id="isParentReward"
                value={state.isParentReward}
                values={inventoryOptions}
                handleChange={handleParentReward}
                disabled={isOtherThenToggleAndEndDateDisabled}
              /> */}
            </Col>
          </Row>
          {state.voucherTypeGroup == 1 && (
            <Row>
              <Col span={8}>
                <Form.Select
                  id="childRewards"
                  mode="multiple"
                  label="Define Child Rewards"
                  value={state.childRewards}
                  options={rewardDataOptions}
                  onChange={handleChange}
                  disabled={isOtherThenToggleAndEndDateDisabled}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col span={8}>
              <ToggleButton
                label="Is this a Highlight/Spotlight reward?"
                id="isHighlightedReward"
                value={state.isHighlightedReward}
                values={inventoryOptions}
                handleChange={handleHighlightAward}
                disabled={isOtherThenToggleAndEndDateDisabled}
              />
            </Col>
          </Row>
        </RewardDetailsWrapper>

        <RewardDetailsWrapper style={{paddingTop: 12}}>
          <Typography.Title level={3} color="primary">
            REWARD COSTING
          </Typography.Title>
          <Row gutter={32}>
            <Col span={6}>
              <InputNumberWrapper>
                <Form.InputNumber
                  id="point"
                  name="point"
                  label="Points Cost"
                  min={0}
                  max={1000000}
                  width={102}
                  value={state.point}
                  onChange={handleChange}
                  disabled={isOtherThenToggleAndEndDateDisabled}
                  precision={0}
                />
                <InputNumberUnit>Points</InputNumberUnit>
              </InputNumberWrapper>
            </Col>
            <Col span={6}>
              <InputNumberWrapper>
                <Form.InputNumber
                  id="amount"
                  name="amount"
                  label="Currency Cost"
                  min={0}
                  max={1000000}
                  width={102}
                  value={state.amount}
                  onChange={handleChange}
                  disabled={isOtherThenToggleAndEndDateDisabled}
                  precision={2}
                />
                <InputNumberUnit>Dollars</InputNumberUnit>
              </InputNumberWrapper>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={6}>
              <InputNumberWrapper>
                <Form.InputNumber
                  id="value"
                  name="value"
                  label="Cost Per Point"
                  min={0}
                  max={1000000}
                  width={102}
                  value={state.value}
                  onChange={handleChange}
                  disabled={isOtherThenToggleAndEndDateDisabled}
                  precision={0}
                />
              </InputNumberWrapper>
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <Form.Input
                id="url"
                name="url"
                label="Adobe Reward Url"
                placeholder="Enter a Reward Url"
                value={state.url}
                onChange={handleChange}
                disabled={isOtherThenToggleAndEndDateDisabled}
              />
            </Col>
          </Row>
        </RewardDetailsWrapper>

        <RewardDetailsWrapper style={{paddingTop: 12}}>
          <Typography.Title level={3} color="primary">
            DISCLAIMER & DESCRIPTIONS
          </Typography.Title>
          <Row>
            <Col span={18}>
              <TextArea
                id="voucherTypeDisclaimer"
                name="voucherTypeDisclaimer"
                label="Disclaimer (Max. 1000 Characters)"
                placeholder="Disclaimer"
                value={state.voucherTypeDisclaimer}
                handleChange={handleChange}
                rows={3}
                maxLength={1000}
              />
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <RichTextEditor
                label="Brief Description (Max. 200 Characters)"
                id="voucherTypeShortDescription"
                value={state.voucherTypeShortDescription}
                handleChange={(data) => handleChange(data, 'voucherTypeShortDescription')}
                placeholder="Description"
              />
              {/* <TextArea
                id="voucherTypeShortDescription"
                name="voucherTypeShortDescription"
                label="Brief Description (Max. 200 Characters)"
                placeholder="Description"
                value={state.voucherTypeShortDescription}
                handleChange={handleChange}
                rows={3}
                maxLength={200}
              /> */}
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <RichTextEditor
                id="voucherTypeFullDescription"
                label="Full Description"
                placeholder="Description"
                value={state.voucherTypeFullDescription}
                handleChange={(data) => handleChange(data, 'voucherTypeFullDescription')}
              />
              {/* <TextArea
                id="voucherTypeFullDescription"
                name="voucherTypeFullDescription"
                label="Full Description"
                placeholder="Description"
                value={state.voucherTypeFullDescription}
                handleChange={handleChange}
                rows={5}
              /> */}
            </Col>
          </Row>

          <Row>
            <Col span={18}>
              <RichTextEditor
                label="Reward Dynamic Body"
                id="rewardDynamicBody"
                value={state.rewardDynamicBody}
                handleChange={(data) => handleChange(data, 'rewardDynamicBody')}
                placeholder="Reward Dynamic Body"
              />
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <TextArea
                id="rewardShippingTimeFrame"
                name="rewardShippingTimeFrame"
                label="Shipping  TimeFrame"
                placeholder="Shipping  TimeFrame"
                value={state.rewardShippingTimeFrame}
                handleChange={handleChange}
                rows={5}
              />
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <RichTextEditor
                label="Reward Dynamic Footer"
                id="rewardDynamicFooter"
                value={state.rewardDynamicFooter}
                handleChange={(data) => handleChange(data, 'rewardDynamicFooter')}
                placeholder="Dynamic Footer"
              />
            </Col>
          </Row>
        </RewardDetailsWrapper>
        {
          <RewardDetailsWrapper style={{paddingTop: 12}}>
            <Typography.Title level={3} color="primary">
              {state.voucherTypeGroup != 1 && 'INVENTORY, '}CATEGORIES & AVAILABILITY
            </Typography.Title>
            {state.voucherTypeGroup != 1 && (
              <>
                <Row>
                  <Col span={4}>
                    <ToggleButton
                      label="Inventory"
                      id="isLimitedInventory"
                      value={state.isLimitedInventory}
                      values={inventoryOptions}
                      handleChange={handleLimitedInventory}
                      disabled={isOtherThenToggleAndEndDateDisabled}
                    />
                  </Col>
                </Row>
                {state.isLimitedInventory && (
                  <>
                    <Row>
                      <Col span={6}>
                        <InputNumberWrapper>
                          <Form.InputNumber
                            id="voucherLimit"
                            name="voucherLimit"
                            label="Quantity"
                            min={1}
                            max={10000}
                            width={102}
                            value={state.voucherLimit}
                            onChange={handleChange}
                            disabled={isOtherThenToggleAndEndDateDisabled}
                            precision={0}
                          />
                          <InputNumberUnit>QTY</InputNumberUnit>
                        </InputNumberWrapper>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <CheckboxContainer>
                          <Form.Checkbox
                            id="isInventoryNotification"
                            options={inventoryCheckboxOptions}
                            value={[state.isInventoryNotification ? 'isChecked' : '']}
                            onChange={onInventoryNotificationclick}
                          />
                        </CheckboxContainer>
                      </Col>
                    </Row>

                    {state.isInventoryNotification && (
                      <>
                        {state.inventoryNotificationQtyList.map((qty, index) => (
                          <Row>
                            <Col span={6}>
                              <InputNumberWrapper>
                                <Form.InputNumber
                                  id={'inventoryNotificationQtyList' + index}
                                  name="inventoryNotificationQtyList"
                                  label={'Quantity Trigger ' + (index + 1)}
                                  min={1}
                                  max={10000}
                                  width={102}
                                  value={qty.quantity}
                                  onChange={(value, key) => handleQtyChange(index, value)}
                                  disabled={isOtherThenToggleAndEndDateDisabled}
                                  precision={0}
                                />
                                <InputNumberUnit>QTY</InputNumberUnit>
                              </InputNumberWrapper>
                            </Col>
                          </Row>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <Row>
              <Col span={6}>
                <Form.Select
                  id="voucherTagCode"
                  options={tagsDataOptions}
                  label="Availability Tag"
                  value={state.voucherTagCode?.voucherTagCode}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Select
                  id="categoryList"
                  options={categoryDataOptions}
                  label="Reward Categories"
                  value={state.categoryList}
                  onChange={handleChange}
                  mode="multiple"
                />
              </Col>
            </Row>
          </RewardDetailsWrapper>
        }

        <RewardImageGallary />

        {state.voucherTypeGroup != 1 && (
          <div style={{paddingTop: 12}}>
            <Typography.Title level={3} color="primary">
              TRIGGERS & RESTRICTIONS
            </Typography.Title>
            <Tabs>
              <Tabs.Tab label="Triggers" id={0} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
              <Tabs.Tab label="Restrictions" id={1} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
            </Tabs>
            {activeTabId === 0 && renderTriggersContent()}
            {activeTabId === 1 && renderRestrictionsContent()}
          </div>
        )}
      </NewRewardFormWrapper>
    )
  }
)

export default NewRewardForm
