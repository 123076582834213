import {useEffect, useRef, useState} from 'react'
import Appconfig from 'app/common/helpers/AppConfig'
import Breadcrumbs from 'lib/components/Breadcrumbs'
import {BreadcrumbsHeaderWrapper} from 'lib/components/Breadcrumbs/Breadcrumbs.styled'
import Button from 'lib/components/Button'
import {useNavigate, useParams} from 'react-router-dom'
import RolesHeader from './RolesHeader'
import {UserManagementFooter} from '../../UserManagementStyled'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import {Modal} from 'antd'
import Typography from 'lib/components/Typography'
import LoadingPanel from 'lib/components/LoadingPanel'
import NewRoleForm, {RoleRefType} from './NewRoleForm'
import ReviewRoles from './ReviewRoles'
import {MarginBottomWrapper, ModalTitle, StyledModalContent} from 'app/common/components/Styled/common.styled'
import UserManagementService from 'app/services/UserManagementService'
import {getRolesRequestObj, inititalRoleRequestObj, updateRoleRequestObj} from '../utils'
import {toast} from 'react-hot-toast'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {
  ROLE_ACTIVATE,
  ROLE_CREATE,
  ROLE_EDIT,
  ROLE_EDIT_FUNCTION,
  ROLE_EDIT_USERS,
  ROLE_VIEW_FUNCTION,
  ROLE_VIEW_USERS,
} from 'app/common/helpers/UserFunctions'

export type AddNewRoleParam = {
  roleCode: string | 'new' | 'view'
}

interface AddNewRoleProps {
  isViewOnly?: boolean
}

const AddNewRole: React.FC<AddNewRoleProps> = ({isViewOnly}) => {
  const roleReqObj = getRolesRequestObj
  const navigate = useNavigate()
  const {roleCode} = useParams<AddNewRoleParam>()
  const actualRoleCode = atob(roleCode || '')
  const [activeStep, setActiveStep] = useState(roleCode === 'new' ? 0 : 1)
  const [loading, setLoading] = useState(false)
  const [open, openConfirmModal] = useState(false)
  const [loadingRole, setLoadingRole] = useState(false)
  const [individualRole, setIndividualRole] = useState<UserManagementTypes.RoleResponseType>(roleReqObj)
  const [categories, setCategories] = useState<UserManagementTypes.CategoryInfoType[]>([])
  const [users, setUsers] = useState<UserManagementTypes.UserInfoType[]>([])
  const [isProcessing, setIsProcessing] = useState(false)
  const roleFormRef = useRef<RoleRefType>(null)
  const isEditing = roleCode !== 'new'
  // for function list in the form
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'FunctionName',
    Strict: true,
    SortDirection: 'asc',
  }
  const initialFunctionResponse: ResponseTypes.PagedResponse<UserManagementTypes.FunctionInfoType[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)
  const [functionsData, setFunctionsData] = useState<ResponseTypes.PagedResponse<UserManagementTypes.FunctionInfoType[]>>(initialFunctionResponse)
  const {roles} = useAuth()
  const showRoleEdit = roles.includes(ROLE_EDIT)
  const showRoleCreate = roles.includes(ROLE_CREATE)
  const showRoleUsers = roles.includes(ROLE_VIEW_USERS)
  const showRoleFunctions = roles.includes(ROLE_VIEW_FUNCTION)
  const showRoleActivate = roles.includes(ROLE_ACTIVATE)
  const showAssignFunction = roles.includes(ROLE_EDIT_FUNCTION)
  const showRemoveUsers = roles.includes(ROLE_EDIT_USERS)

  const allowBack = activeStep === 1

  const searchFunctions = (params: UserManagementTypes.FunctionSearchParams) => {
    UserManagementService.searchFunctions({...params}).then((res) => {
      setFunctionsData(res.data)
    })
  }

  const getCategories = (params: UserManagementTypes.CategorySearchParams) => {
    UserManagementService.searchCategories(params).then((res) => {
      const data = res.data.data
      setCategories(data)
    })
  }

  const getUsers = (params: UserManagementTypes.UserSearchParams) => {
    UserManagementService.searchUser({...params}).then((res) => {
      const data = res.data.data
      setUsers(data)
    })
  }

  const getUserRoleData = () => {
    if (roleCode !== 'new') {
      setLoadingRole(true)
      UserManagementService.searchRoles({roleCode: actualRoleCode}).then((res) => {
        const data = res.data.data[0]
        setIndividualRole(data)
        updateRoleRequestObj(data)
        setLoadingRole(false)
      })
      if (actualRoleCode && roleCode !== 'new') getUsers({roleCode: actualRoleCode})
    }
  }

  useEffect(() => {
    if (activeStep === 0) {
      searchFunctions(initialPaginationObj)
      getCategories(initialPaginationObj)
    }
  }, [activeStep])

  useEffect(() => {
    getUserRoleData()
    // set initial req obj after leaving the page
    return () => {
      updateRoleRequestObj(inititalRoleRequestObj)
    }
  }, [])

  const handleFilterAndSort = (category: string[], sortby: string) => {
    const sortParams = sortby.split(',')

    let sortByAccess = 'ALL'
    let sortDirection = ''
    if (sortParams.length > 0) {
      sortByAccess = sortParams[0]
    }
    if (sortParams.length > 1) {
      sortDirection = sortParams[1]
    }
    setPaginationObj(initialPaginationObj)
    const funcReqParams = {categoryCodeMultiple: category.join(','), sortDirection, sortByAccess, ...initialPaginationObj}
    searchFunctions({...funcReqParams})
  }

  // handle pagination for Functions
  const handleOnPageChange = (page: number, pageSize: number) => {
    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    searchFunctions({...newPaginationObj})
  }

  const showRolePreview = () => {
    if (roleFormRef.current?.validate()) {
      setLoading(true)
      openConfirmModal(true)
      setIsProcessing(true)
      const params = roleCode !== 'new' ? {roleCode: actualRoleCode} : undefined
      UserManagementService.saveRole(getRolesRequestObj, params)
        .then((res) => {
          const data = res.data.data
          updateRoleRequestObj(data)
          toast.success('Data Saved Successfully!!!')
          navigate(`/user-management`)
        })
        .finally(() => {
          openConfirmModal(false)
          setIsProcessing(false)
        })
    }
    setLoading(false)
  }

  const handleEditRole = () => {
    getUserRoleData()
    setActiveStep(0)
  }

  const handleCancelConfirm = () => {
    // updateRewardsRequestObj(initialRewardsRequestObj)
    sessionStorage.setItem('activeTab', '0')
    navigate(-1)
  }

  const handleOk = () => {
    openConfirmModal(false)
    handleCancelConfirm()
  }

  const handleCancelNewRole = () => {
    if (isViewOnly === true) {
      handleCancelConfirm()
    } else {
      openConfirmModal(true)
    }
  }

  const handleCancelModal = () => {
    openConfirmModal(false)
  }

  const renderModalFooter = () => {
    return null
  }

  const renderSaveButton = () => {
    return (
      <>
        {activeStep === 0 && (
          <Button type="link" onClick={handleCancelNewRole}>
            Cancel New Role
          </Button>
        )}
        <Button
          loading={loading}
          onClick={showRolePreview}
          type="primary"
          disabled={false}
          $endIcon={<IconArrowRightLong color={Colors.WHITE} size={16} />}
        >
          Save
        </Button>
      </>
    )
  }

  const renderRolesFooter = () => {
    return (
      (!isViewOnly || showAssignFunction) && (
        <UserManagementFooter>
          {allowBack && (showRoleEdit || showAssignFunction) ? (
            <Button type="primary" onClick={handleEditRole}>
              Edit
            </Button>
          ) : (
            <></>
          )}
          {(showRoleEdit || showRoleCreate || showAssignFunction) && activeStep === 0 && renderSaveButton()}
        </UserManagementFooter>
      )
    )
  }

  const renderRolesBody = () => {
    if (loadingRole) {
      return <LoadingPanel />
    }
    if (roleCode !== 'new' && !individualRole) {
      return <div>No Role found with {actualRoleCode}</div>
    }
    if (activeStep === 0) {
      return (
        <NewRoleForm
          ref={roleFormRef}
          roleData={individualRole}
          functionsData={functionsData}
          handleOnPageChange={handleOnPageChange}
          paginationObj={paginationObj}
          categoriesList={categories}
          handleFilterAndSort={handleFilterAndSort}
        />
      )
    }
    return (
      <ReviewRoles
        isViewOnly={!!isViewOnly}
        userList={users}
        setUsers={setUsers}
        showEditRole={showRoleEdit}
        showRoleUsers={showRoleUsers}
        showRoleFunctions={showRoleFunctions}
        showRoleActivate={showRoleActivate}
        showAssignFunction={showAssignFunction}
        showRemoveUsers={showRemoveUsers}
      />
    )
  }

  const getModalContent = () => {
    if (isProcessing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1}>Are you sure?</Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">
            Are you sure you want to cancel {isEditing ? 'editing' : 'adding'} {individualRole.roleName}?
          </Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="primary" key="submit" onClick={handleOk}>
            Continue with cancel
          </Button>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" key="back" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  return (
    <div>
      <BreadcrumbsHeaderWrapper>
        <Breadcrumbs backLinkLabel="User Management" backLinkPath={`/user-management`} activePageLabel="Add New Role" />
        <Button type="link" onClick={handleCancelNewRole}>
          {roleCode === 'new' ? 'Cancel New Role' : 'Cancel'}
        </Button>
      </BreadcrumbsHeaderWrapper>
      <RolesHeader activeStep={activeStep} roleName={individualRole.roleName} />
      {renderRolesBody()}
      {renderRolesFooter()}
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={renderModalFooter()}>
          {getModalContent()}
        </Modal>
      )}
    </div>
  )
}

export default AddNewRole
