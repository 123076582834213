export const baseUrl = window.env.REACT_APP_API_ROOT_PATH
const urlPrefix = window.env.REACT_APP_API_PREFIX
const MEMBER_ROOT_PATH = urlPrefix + 'member'
const REDEEM_ROOT_PATH = urlPrefix + 'redeem'
const BOOKING_ROOT_PATH = urlPrefix + 'booking'
const PROMOTION_ROOT_PATH = urlPrefix + 'promotion'
const PRODUCT_ROOT_PATH = urlPrefix + 'product'
const REWARDS_ROOT_PATH = urlPrefix + 'redeem'
const USER_ROOT_PATH = urlPrefix + 'user'
const EVENT_ROOT_PATH = urlPrefix + 'event'

const ServiceConfig = {
  login: `${baseUrl}/${USER_ROOT_PATH}/connect/token`,
  forgotToken: `${baseUrl}/${USER_ROOT_PATH}/User/ForgotToken`,
  resetPassword: `${baseUrl}/${USER_ROOT_PATH}/User/ResetPassword`,
  createPassword: `${baseUrl}/${USER_ROOT_PATH}/User/CreatePassword`,
  memberSearch: `${baseUrl}/${MEMBER_ROOT_PATH}/member/Search`,
  memberDetail: `${baseUrl}/${MEMBER_ROOT_PATH}/member/Detail`,
  memberStatus: `${baseUrl}/${MEMBER_ROOT_PATH}/member/Status`,
  saveMember: `${baseUrl}/${MEMBER_ROOT_PATH}/member`,
  memberCount: `${baseUrl}/${MEMBER_ROOT_PATH}/member/Count?partnerCode`,
  changeMemberStatus: `${baseUrl}/${MEMBER_ROOT_PATH}/member/ChangeStatus`,
  voucherSearch: `${baseUrl}/${REDEEM_ROOT_PATH}/Voucher/SearchVoucherType`,
  generateVoucher: `${baseUrl}/${REDEEM_ROOT_PATH}/Voucher/GenerateVoucher`,
  getVoucherCategory: `${baseUrl}/${REDEEM_ROOT_PATH}/Voucher/Category`,
  getTags: `${baseUrl}/${REDEEM_ROOT_PATH}/Voucher/Tag`,
  aggregatedSearch: `${baseUrl}/${BOOKING_ROOT_PATH}/Booking/AggregatedSearch`,
  memberBalance: `${baseUrl}/${BOOKING_ROOT_PATH}/MemberBalance`,
  adjustPoints: `${baseUrl}/${BOOKING_ROOT_PATH}/AdjustPoints`,
  memberMerge: `${baseUrl}/${BOOKING_ROOT_PATH}/MemberMerge`,
  tier: `${baseUrl}/${MEMBER_ROOT_PATH}/tier`,
  changetier: `${baseUrl}/${MEMBER_ROOT_PATH}/member/ChangeTier`,
  saveTier: `${baseUrl}/${MEMBER_ROOT_PATH}/tier`,
  getPartners: `${baseUrl}/${PROMOTION_ROOT_PATH}/Partner`,
  getPoints: `${baseUrl}/${PROMOTION_ROOT_PATH}/Point`,
  searchPromotions: `${baseUrl}/${PROMOTION_ROOT_PATH}/Promotion/Search`,
  promotion: `${baseUrl}/${PROMOTION_ROOT_PATH}/Promotion`,
  removePromotion: `${baseUrl}/${PROMOTION_ROOT_PATH}/Promotion/RemovePromotion`,
  getProductCategory: `${baseUrl}/${PRODUCT_ROOT_PATH}/Product/Category`,
  getProductCategoryValue: `${baseUrl}/${PRODUCT_ROOT_PATH}/Product/CategoryValue`,
  promotionStatus: `${baseUrl}/${PROMOTION_ROOT_PATH}/Status`,
  searchRewards: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/SearchVoucherType`,
  searchRewardDetail: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/VoucherTypeDetail`,
  saveRewards: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher`,
  saveRewardPriceHistory: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/PriceHistory`,
  saveVoucherImage: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/Image`,
  deleteVoucherImage: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/ImageDelete`,
  removeReward: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/RemoveVoucher`,
  getActiveoffers: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/GetActiveOffers`,
  issueReward: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/IssueVoucher`,
  getUserRoles: `${baseUrl}/${USER_ROOT_PATH}/User/Role`,
  getUsers: `${baseUrl}/${USER_ROOT_PATH}/User/Search`,
  getFunctions: `${baseUrl}/${USER_ROOT_PATH}/User/Functions`,
  saveRole: `${baseUrl}/${USER_ROOT_PATH}/User/Role`,
  getCategories: `${baseUrl}/${USER_ROOT_PATH}/User/Category`,
  saveUser: `${baseUrl}/${USER_ROOT_PATH}/User`,
  removeRoleFunction: `${baseUrl}/${USER_ROOT_PATH}/User/removeRoleFunction`,
  removeUserRole: `${baseUrl}/${USER_ROOT_PATH}/User/removeUserRole`,
  searchSegmentation: `${baseUrl}/${MEMBER_ROOT_PATH}/member/Segmentation`,
  getProducts: `${baseUrl}/${PRODUCT_ROOT_PATH}/Product`,
  getSegmentMembers: `${baseUrl}/${MEMBER_ROOT_PATH}/member/SegmentSearch`,
  postOptedMemberData: `${baseUrl}/${MEMBER_ROOT_PATH}/member/OptedIn`,
  validateResetToken: `${baseUrl}/${USER_ROOT_PATH}/User/ResetPasswordToken`,
  getPromotionsForMember: `${baseUrl}/${PROMOTION_ROOT_PATH}/Promotion/eligible-promotions`,
  getAllPoints: `${baseUrl}/${PROMOTION_ROOT_PATH}/Point`,
  getCurrenies: `${baseUrl}/${PROMOTION_ROOT_PATH}/Currency`,
  getPointTypeCodes: `${baseUrl}/${PROMOTION_ROOT_PATH}/PointType`,
  memberImportDetail: `${baseUrl}/${MEMBER_ROOT_PATH}/member/MemberImportDetail`,
  productImportDetail: `${baseUrl}/${PRODUCT_ROOT_PATH}/product/ProductImportDetail`,
  transactionImportDetail: `${baseUrl}/${BOOKING_ROOT_PATH}/Transaction/TransactionImportDetail`,
  voucherImportDetail: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/VoucherImportDetail`,

  memberImportFile: `${baseUrl}/${MEMBER_ROOT_PATH}/member/Import`,
  productImportFile: `${baseUrl}/${PRODUCT_ROOT_PATH}/product/Import`,
  transactionImportFile: `${baseUrl}/${BOOKING_ROOT_PATH}/Transaction/Import`,
  voucherImportFile: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/Import`,

  memberImportFileDownload: `${baseUrl}/${MEMBER_ROOT_PATH}/member/ExportMemberRecords`,
  productImportFileDownload: `${baseUrl}/${PRODUCT_ROOT_PATH}/product/ExportProductRecords`,
  transactionImportFileDownload: `${baseUrl}/${BOOKING_ROOT_PATH}/ExportTransactionRecords`,
  voucherImportFileDownload: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/ExportVoucherRecords`,

  memberSampleImportFileDownload: `${baseUrl}/${MEMBER_ROOT_PATH}/member/ExportSampleImport`,
  productSampleImportFileDownload: `${baseUrl}/${PRODUCT_ROOT_PATH}/product/ExportSampleImport`,
  transactionSampleImportFileDownload: `${baseUrl}/${BOOKING_ROOT_PATH}/ExportSampleImport`,
  voucherSampleImportFileDownload: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/ExportSampleImport`,
  activityLog: `${baseUrl}/${EVENT_ROOT_PATH}/Event/AuditLog`,
  searchRewardInfo: `${baseUrl}/${REWARDS_ROOT_PATH}/Voucher/SearchVoucher`,
  
}

export default ServiceConfig
