import {CheckboxOptionType} from 'antd'
import Appconfig from 'app/common/helpers/AppConfig'
import { FormTypes } from 'lib/components/Form/Form'
import {ToggleOptionType} from 'lib/components/ToggleButton/ToggleButton'
import cloneDeep from 'lodash/cloneDeep'
export const defaultInventoryNotificationQty = [
  {
    quantity: 0,
    isNotificationSent: false,
  },
  {
    quantity: 0,
    isNotificationSent: false,
  },
  {
    quantity: 0,
    isNotificationSent: false,
  },
]
export const rewardRuleDataInitialState: RewardsTypes.RewardRuleDataType = {
  pointRedemption: {
    triggerData: {
      isAllTiers: true,
      tierData: null,
    },
  },
  tierBenefit: {
    triggerData: {
      isAllTiers: true,
      tierData: null,
    },
  },
  stateRestriction: {
    isRestrictionAvaliable: false,
    state: null,
  },
  voucherLimitRule: null,
}

export let initialRewardsRequestObj: RewardsTypes.RewardResponseBL = {
  rowStatus: Appconfig.rewards.defaultValus.rowStatus,
  voucherLimit: 0,
  voucherStatus: Appconfig.rewards.defaultValus.voucherStatusCode,
  voucherTypeCategory: Appconfig.rewards.defaultValus.voucherTypeCategory,
  voucherTypeCode: '',
  voucherTypeDescription: '',
  voucherTypeId: 0,
  voucherName: '',
  voucherTypeSubCategory: '',
  isExpiryNotification: false,

  isRedemption: false,
  isTierBenefit: false,
  partnerCode: '',
  validFrom: null,
  validUntil: null,
  ruleData: [],
  properties: {},
  enabled: false,
  points: 0,
  point: 0,
  amount: 0,
  url: '',
  value: 0,
  transferRate: 0.01,
  expiryNotificationDays: 0,

  inventoryNotificationFrequency: 0,
  expiryNotificationFrequency: 0,
  currencyCode: 'USD',
  redeemPartner: [],
  rewardRuleData: rewardRuleDataInitialState,
  isLimitedInventory: false,
  voucherTypeSkuCode: '',
  voucherTypeGroup: 3,
  childRewards: [],
  isHighlightedReward: false,
  voucherTagCode: undefined,
  categoryList: [],
  voucherTypeShortDescription: '',
  voucherTypeFullDescription: '',
  voucherTypeDisclaimer: '',
  rewardPrimaryImage: '',
  rewardAdditionalImage: [],
  rewardThumbnail: '',
  issuedDate: undefined,
  convertedDate: undefined,
  cancelledDate: undefined,
  rejectedDate: undefined,
  saleFrom: undefined,
  saleUntil: undefined,
  salePoint: 0,
  rewardDynamicBody: '',
  rewardShippingTimeFrame: '',
  rewardDynamicFooter: '',
  isInventoryNotification: false,
  inventoryNotificationQtyList: cloneDeep([]),
}

export let getRewardsRequestObj: RewardsTypes.RewardResponseBL = cloneDeep(initialRewardsRequestObj)

export const updateRewardsRequestObj = (data: RewardsTypes.RewardResponseBL) => {
  getRewardsRequestObj = cloneDeep(data)
}

export const updateRewardRuleData = (data: RewardsTypes.RewardRuleDataType) => {
  getRewardsRequestObj.rewardRuleData = data
}

export const TiersOptions: CheckboxOptionType[] = [
  {
    label: 'All Tiers',
    value: 'All',
  },
  {
    label: 'Specific Tier',
    value: 'tiers',
  },
]

export const toggleOptions: ToggleOptionType[] = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

export const rewardTypeOptions = [
  // {label: 'PERCENTAGE', value: 'PERCENTAGE'},
  {label: 'PRODUCT', value: 'PRODUCT'},
  {label: 'VOUCHER', value: 'VOUCHER'},
  // {label: 'AMOUNT', value: 'AMOUNT'},
  // {label: 'LIMITED', value: 'LIMITED'},
  // {label: 'UNLIMITED', value: 'UNLIMITED'},
  {label: 'SWEEPSTAKE', value: 'SWEEPSTAKE'},
]

export const rewardTypeLimitOptions = [
  {label: 'Day', value: 'Day'},
  {label: 'Week', value: 'Week'},
  {label: 'Month', value: 'Month'},
  {label: 'Year', value: 'Year'},
  {label: 'Phase', value: 'Phase'},
]

export const noDateCheckboxOptions: FormTypes.Options[] = [
  {
    label: 'No end date',
    value: 'isChecked',
  },
]

export let triggerRules: RewardsTypes.TriggerRulesData = {
  pointRedemption: {
    id: 'redemption',
    question: 'Should this reward be available for points redemptions?',
    subQue: 'Which tiers can redeem?',
  },
  tierBenefit: {
    id: 'benefit',
    question: 'Should this reward be available as a tier benefit',
    subQue: '',
  },
}

export const rewardStatus = {
  INPROGRESS: 'INPROGRESS',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  LIVE: 'LIVE',
  ENDED: 'ENDED',
  REJECTED: 'REJECTED',
}

export let restrictionsData: RewardsTypes.RestrictedDataType = {
  id: 'stateRestriction',
  question: 'Should this reward be restricted by state?',
}

export let rewardTiersData: MemebershipTypes.TierCountType[] = []

export const updateRewardTiersData = (data: MemebershipTypes.TierCountType[]) => {
  rewardTiersData = cloneDeep(data)
}

export const states = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },

  {
    label: 'Wyoming',
    value: 'WY',
  },
]

export const inventoryOptions: ToggleOptionType[] = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

export const VoucherTypeGroupOptions: FormTypes.Options[] = [
  {label: 'Parent', value: 1},
  {label: 'Child', value: 2},
  {label: 'Independent', value: 3},
]

export const limitOptions: FormTypes.Options[] = [
  {
    label: 'set USER LEVEL Limits',
    value: 'userLimit',
  },
  {
    label: 'set Reward LEVEL Limits',
    value: 'campaignLimit',
  },
]

export const limitFrequecyOptions: FormTypes.Options[] = [
  {
    label: 'Day',
    value: 'Day',
  },
  {
    label: 'Week',
    value: 'Week',
  },
  {
    label: 'Month',
    value: 'Month',
  },
  {
    label: 'Year',
    value: 'Year',
  },
  {
    label: 'Phase',
    value: 'Phase',
  },
]

export enum LIMIT_TYPES {
  userLimit = 'userLimit',
  campaignLimit = 'rewardLimit',
}

export const getChildRewardPayloadArr = (options: FormTypes.Options[], data: string[]): RewardsTypes.ChildRewardPayloadType[] => {
  const result = options
    .filter((item) => data.includes(item.value as string))
    .map((reward) => ({
      rewardName: reward.label?.toString().substring(0, reward.label?.toString().lastIndexOf('-')) as string,
      rewardCode: reward.value as string,
    }))
  return result
}

export const getTagNameByCode = (options: FormTypes.Options[], code: string): string => {
  return (options.find((item) => item.value === code)?.label as string) || ''
}

export const getCatgeoryNames = (options: FormTypes.Options[], data: RewardsTypes.CategoryList[]): string[] => {
  let categoryNames: string[] = []
  let newCategoryList = data.map((category) => category.categoryName)
  categoryNames = options.filter((item) => newCategoryList.includes(item.label as string)).map((categroy) => categroy.label as string)
  return categoryNames
}

export const encodeDecodeImageUrl = (imageUrl: string) => {
  const encodedUrl = imageUrl
    .replace(/ /g, '%20') // Encode spaces
    .replace(/\(/g, '%28') // Encode left parenthesis
    .replace(/\)/g, '%29') // Encode right parenthesis
  return encodedUrl
}



export const isAscending = (arr: number[]) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i + 1] <= arr[i]) {
      return false
    }
  }
  return true
}